<template>
  <div class="order-xl-1 px-0">
    <card shadow type="secondary">
      <template v-slot:header>
      <div class="bg-white border-0">
        <div class="row">
          <div class="col-12">
            <i class="fas fa-exclamation-triangle"></i>
            <h3 class="text-center">{{getTrans('messages.account_verification')}}</h3>
          </div>
          <small class="text-center text-muted"
            >{{getTrans('messages.verify_text')}}</small
          >
        </div>
      </div>
      <div class="text-right">
        <base-button type="primary" @click="setSub">{{getTrans('messages.verify_account')}}</base-button>
      </div>
      </template>
    </card>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      registerSteps: 0,
    };
  },
  methods: {
    nextStep() {
      this.registerSteps += 1;
    },
    setSub() {
      this.$emit("setEmail");
    },
  },
};
</script>
<style></style>
