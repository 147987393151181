<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header bg-transparent">
            <div class="text-center text-muted">
              <h2>{{getTrans('messages.model')}}</h2>
              <small
                >{{getTrans('messages.register_model_text')}}</small
              >
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4"
                  @click="setType('model')"
                  >{{getTrans('messages.sign_up_model_button')}}</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header bg-transparent">
            <div class="text-center text-muted">
              <h2>{{getTrans('messages.studio')}}</h2>
              <small
                >{{getTrans('messages.register_studio_text')}}</small
              >
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4"
                  @click="setType('studio')"
                  >{{getTrans('messages.sign_up_studio_button')}}</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header bg-transparent">
            <div class="text-center text-muted">
              <h2>{{getTrans('messages.affiliate')}}</h2>
              <small
                >{{getTrans('messages.register_affiliate_text')}}</small
              >
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4"
                  @click="setType('affiliate')"
                  >{{getTrans('messages.sign_up_affiliate_button')}}</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      registerSteps: 0
    };
  },
  methods: {
    nextStep() {
      this.registerSteps += 1;
    },
    setType(type) {
      this.$emit("setType", type);
    }
  }
};
</script>
<style></style>
