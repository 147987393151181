<template>
  <div class="container-fluid">
    <div class="col-12">
      <card class="register-steps">
        <el-steps :active="registerSteps">
          <el-step
            v-for="step in fullSteps"
            :key="step.id"
            :title="getTrans(step.title)"
            :description="getTrans(step.description)"
          ></el-step>
        </el-steps>
      </card>
      <account-type v-if="registerSteps == 0" @setType="chooseAccount"></account-type>
      <account-details
        v-if="registerSteps == 1"
        :type="accountType"
        @userCreated="userCreated"
        @back="goBack"
      ></account-details>
      <email-verify v-if="registerSteps == 2" @setEmail="emailVerification"></email-verify>
      <sub-plan v-if="registerSteps == 3" @setSub="chooseSubcription"></sub-plan>
      <pay-page v-if="registerSteps == 4" :subscription="subscription" @back="goBack"></pay-page>
    </div>
  </div>
</template>
<script>
import { ElStep, ElSteps } from "element-plus";
import AccountType from "./Register/AccountType.vue";
import AccountDetails from "./Register/AccountDetails.vue";
import EmailVerify from "./Register/EmailVerify.vue";
import SubscriptionPlan from "@/views/Subscription/SubscriptionOverview.vue";
import PaymentPage from "./PaymentPage.vue";
export default {
  components: {
    [ElStep.name]: ElStep,
    [ElSteps.name]: ElSteps,
    "account-type": AccountType,
    "account-details": AccountDetails,
    "email-verify": EmailVerify,
    "sub-plan": SubscriptionPlan,
    "pay-page": PaymentPage
  },
  data() {
    return {
      registerSteps: 0,
      accountType: "",
      subscription: null,
      fullSteps: [
        {
          id: 1,
          title: "extra.reg_step_one",
          description:"extra.reg_type",
        },
        {
          id: 2,
          title: "extra.reg_step_two",
          description:"extra.reg_details",
        },
        {
          id: 3,
          title: "extra.reg_step_three",
          description:"extra.reg_email_verify",
        }
      ]
    };
  },
  computed: {
  },
  methods: {
    nextStep() {
      this.registerSteps += 1;
    },
    chooseAccount(type) {
      this.accountType = type;
      this.registerSteps = 1;
    },
    chooseSubcription(sub) {
      this.subscription = sub;
      this.nextStep();
    },
    userCreated() {
      this.registerSteps = 2;
    },
    emailVerification() {
      this.registerSteps = 3;
    },
    subscriptionPlan() {
      this.registerSteps = 4;
    },
    paymentPage() {
      this.registerSteps = 5;
    },
    goBack() {
      this.registerSteps--;
    }
  }
}
</script>
<style>
</style>
