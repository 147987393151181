<template>
  <div class="order-xl-1 px-0">
    <card shadow type="secondary">
      <template v-slot:header>
        <div class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-12">
              <h3 class="text-center">{{getTrans('messages.account_details')}}</h3>
            </div>
          </div>
        </div>
      </template>
      <h3>{{getTrans('messages.reg_user_details')}}</h3>
      <form @submit.prevent>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <base-input :error="getError('name')"
              alternative=""
              :label="getTrans('messages.reg_name')"
              :placeholder="getTrans('messages.reg_name')"
              prepend-icon="fas fa-user"
              input-classes="form-control-alternative"
              v-model="userInfo.name"
            />
          </div>
          <div class="col-lg-6">
            <!-- eslint-disable-next-line -->
            <base-input placeholder="jesse@example.com"
              :error="getError('email')"
              alternative=""
              :label="getTrans('messages.email_address')"
              prepend-icon="fas fa-envelope-square"
              input-classes="form-control-alternative"
              v-model="userInfo.email"
            />
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <base-input :error="getError('password')"
              alternative=""
              :label="getTrans('messages.new_password')"
              :placeholder="getTrans('messages.password')"
              prepend-icon="fas fa-key"
              input-classes="form-control-alternative"
              type="password"
              v-model="userInfo.password"
            />
          </div>
          <div class="col-lg-6">
            <base-input
              alternative=""
              :label="getTrans('messages.confirm_passw')"
              :placeholder="getTrans('messages.password')"
              prepend-icon="fas fa-key"
              input-classes="form-control-alternative"
              type="password"
              v-model="userInfo.confirmpassword"
            />
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <base-input :error="getError('city')"
              alternative=""
              :label="getTrans('messages.city')"
              :placeholder="getTrans('messages.your_city')"
              prepend-icon="fas fa-city"
              input-classes="form-control-alternative"
              v-model="userInfo.city"
            />
          </div>
          <div class="col-lg-6">
            <base-input :error="getError('country')" :label="getTrans('messages.country')" prepend-icon="fas fa-flag">
              <select class="form-control" v-model="userInfo.country">
                <option value="" selected="true">{{getTrans('messages.reg_studio_country')}}</option>
                <option v-for="country in getCountries" :key="country.id" :value="country.id">{{country.name}}</option>
              </select>
            </base-input>
          </div>
        </div>
        <div v-if="type == 'studio'">
          <hr class="my-4" />
          <h3>{{getTrans('messages.reg_studio_details')}}</h3>
          <div class="row">
            <div class="col-lg-6">
              <base-input :error="getError('studioname')"
                alternative=""
                label="Studio name"
                prepend-icon="fas fa-building"
                input-classes="form-control-alternative"
                v-model="userInfo.studio_name"
              />
            </div>
            <div class="col-lg-6">
              <!-- eslint-disable-next-line -->
              <base-input placeholder="http://www.studio.com"
                :error="getError('studiowebsite')"
                alternative=""
                label="Studio website"
                prepend-icon="fas fa-building"
                input-classes="form-control-alternative"
                v-model="userInfo.studio_site"
              />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6">
              <base-input :error="getError('studioemail')"
                alternative=""
                label="Contact email"
                prepend-icon="fas fa-envelope-square"
                input-classes="form-control-alternative"
                v-model="userInfo.studio_email"
              />
            </div>
            <div class="col-lg-3">
              <base-input label="Country" prepend-icon="fas fa-flag" :error="getError('studiocountry')">
                <select class="form-control" v-model="userInfo.studio_country" >
                  <option value="" selected="true">{{getTrans('messages.reg_studio_country')}}</option>
                  <option v-for="country in getCountries" :key="country.id" :value="country.id">{{country.name}}</option>
                </select>
              </base-input>
            </div>
            <div class="col-lg-3">
              <base-input :error="getError('studiocity')"
                alternative=""
                label="City"
                prepend-icon="fas fa-city"
                input-classes="form-control-alternative"
                v-model="userInfo.studio_city"
              />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6">
              <base-input :error="getError('studiophone')"
                alternative=""
                label="Phone number"
                prepend-icon="fas fa-phone"
                input-classes="form-control-alternative"
                v-model="userInfo.studio_phone"
              />
            </div>
            <div class="col-lg-6">
              <base-input label="Total performers" prepend-icon="fas fa-users">
                <select class="form-control" v-model="userInfo.studio_size">
                  <option
                    v-for="(totalperformersitem, index) in totals.pernumber"
                    :key="index"
                    :value="totalperformersitem.value"
                    >{{ totalperformersitem.label }}</option
                  >
                </select>
              </base-input>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <base-checkbox class="align-middle" v-model="readTerms">{{getTrans('messages.agree')}} </base-checkbox
          ><a href="https://divatraffic.com/terms">{{getTrans('messages.terms_conditions')}}</a>
        </div>
        <hr class="my-4" />
        <div class="row align-items-center">
          <div class="col-6 text-left">
            <base-button type="secondary" @click="back">{{getTrans('messages.go_back')}}</base-button>
          </div>
          <div class="col-6 text-right">
            <base-button v-if="readTerms" type="primary" @click="register">{{getTrans('messages.register')}}</base-button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import BaseButton from "@/components/BaseButton.vue";
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from "element-plus";
export default {
  components: {
    BaseButton,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu
  },
  props: ["type"],
  data() {
    return {
      userInfo:{
        name: "",
        email: "",
        password: "",
        confirmpassword: "",
        city: "",
        country: "",
        studio_name: "",
        studio_site: "",
        studio_email: "",
        studio_country: "",
        studio_city: "",
        studio_phone: "",
        studio_size: "",
      },
      readTerms: false,
      totals: {
        performers: "",
        pernumber: [
          {
            value: "1-10",
            label: "1-10"
          },
          {
            value: "10-25",
            label: "10-25"
          },
          {
            value: "25-50",
            label: "25-50"
          },
          {
            value: "50-100",
            label: "50-100"
          },
          {
            value: "100+",
            label: "100+"
          }
        ]
      },
    };
  },
  computed: {
    ...mapGetters('Country', ['getCountries']),
    ...mapGetters('Error', ['getError']),
  },
  methods: {
    nextStep() {
      this.registerSteps += 1;

    },
    back() {
      this.$emit("back");
    },
    register(){
      var newUser;

      if (this.type=="studio") {
        newUser = this.createStudioFromForm()
      }
      if (this.type=="model") {
        newUser = this.createModelFromForm()
      }
      if (this.type=="affiliate") {
        newUser = this.createAffiliateFromForm()
      }

      this.$store.dispatch("User/register", newUser).then(() => {
        this.$store.dispatch("User/get").then(() => {
          this.$emit("userCreated");
        });
      }).catch (()=>{
      });
    },
    createModelFromForm() {
      return {
        name: this.userInfo.name,
        email: this.userInfo.email,
        password: this.userInfo.password,
        password_confirmation: this.userInfo.confirmpassword,
        country: this.userInfo.country,
        city: this.userInfo.city,
        terms: this.readTerms,
        role: 1,
      }
    },
    createStudioFromForm() {
      let account = this.createModelFromForm();
      Object.assign(account, {
          role: 4,
          studioname: this.userInfo.studio_name,
          studioemail: this.userInfo.studio_email,
          studiophone_pre: this.userInfo.studio_phone_prefix,
          studiophone: this.userInfo.studio_phone,
          studiowebsite: this.userInfo.studio_site,
          studiocountry: this.userInfo.studio_country ? this.userInfo.studio_country : 0,
          studiocity: this.userInfo.studio_city,
          studioperformers: this.userInfo.studio_size ? this.userInfo.studio_size : 0
      });
      return account;
    },
    createAffiliateFromForm() {
      let account = this.createModelFromForm();
      account.role = 8; //affiliate role
      return account;
    }
  },

  mounted() {
    this.$store.dispatch('Country/get');
  },

  unmounted() {
    this.$store.dispatch('Error/RESET');
  },
};
</script>
<style></style>
